import React, { Component } from 'react';

export default class Map extends Component {
    render() {
        return (
            <div id="map">
                <iframe src="https://snazzymaps.com/embed/1010" width="100%" height="600px" style={{ border: 'none' }}></iframe>
            </div>
        );
    }
}
