import React, { Component } from 'react';
import DsoftLogo from '../../images/dsoft-logo.svg';

export default class Footer extends Component {
    render() {
        let year = new Date().getFullYear();

        return (
            <div id="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mx-auto">
                            Copyright &copy; {year} <b>RockersPro</b>
                            <br />
                            <span id="made-by">
                                <a href="https://dsoft.pl" target="_blank"><b>Made by</b> <img src={DsoftLogo} id="dsoft-logo" alt="DSoft logo" title="DSoft" /></a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
