import React, { Component } from 'react';

export default class Header extends Component {
    static displayName = Header.name;

    render() {
        return (
            <div id="services">
                Usługi
            </div>
        );
    }
}
