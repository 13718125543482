import React, { Component } from 'react';
import ContactSection from '../common/ContactSection';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import VinylsImage from '../../images/vinyls.jpg';
import CompactDiscsImage from '../../images/compactdiscs.jpg';
import WarehouseImage from '../../images/warehouse.jpg';

export default class SimpleContact extends Component {
    render() {
        return (
            <div id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <ContactSection phone="+48 660 475 100" mail="produkcja@rockers.pl" name="Produkcja" image={CompactDiscsImage}>
                                Produkcja płyt CD i winylowych. Dostępny szeroki wybór opakowań typu jewel box, ecopack, digipack i gatefold. 
                            </ContactSection>
                            <hr className="d-block d-md-none" />
                        </div>
                        <div className="col-md-4">
                            <ContactSection phone="+48 664 060 357" mail="handel@rockers.pl" name="Dystrybucja" image={WarehouseImage}>
                                <FontAwesomeIcon icon={faExternalLinkAlt} /> <a href="http://katalog.rockerspro.pl" title="Katalog online" target="_blank">Katalog on-line</a><br />
                                <FontAwesomeIcon icon={faExternalLinkAlt} /> <a href="http://api.rockerspro.pl/katalog/rockers_hurt.csv" title="Katalog w formacie CSV">Pobierz katalog CSV</a><br />
                            </ContactSection>
                            <hr className="d-block d-md-none" />
                        </div>
                        <div className="col-md-4">
                            <ContactSection phone={["+48 662 168 846", "+48 717 500 099"]} mail="rockers@rockers.pl" name="Sklep" image={VinylsImage}>
                                <FontAwesomeIcon icon={faExternalLinkAlt} /> <a href="https://rockers.pl" title="Sklep rockers.pl" target="_blank">Sklep Rockers.pl</a><br />
                                <FontAwesomeIcon icon={faExternalLinkAlt} /> <a href="https://allegro.pl/uzytkownik/RockersPro/sklep" title="Sklep Allegro" target="_blank">Sklep Allegro</a><br />
                            </ContactSection>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <hr />
                            Z muzyką jesteśmy związani od ponad 20 lat. RockersPro to selektywna soczewka i dostawca najciekawszych i najświeższych wydawnictw światowej muzyki promowanej przez niezależne labele. Nie ograniczamy się gatunkowo, bo jesteśmy ciekawi nowego i wierzymy, że nasi klienci chcą z nami poznawać nowe trendy, ale też sięgać do ciekawych wydawnictw z przeszłości. Rozszerzeniem naszej działalności jest produkcja płyt CD oraz winylowych. W swojej ofercie posiadamy możliwości stworzenia winyla o dowolnej barwie, np. wielokolorowe splatery. Dodatkowo posiadamy szeroki wachlarz opakowań dla płyt CD, jak i dla winyli. RockersPro jest idealnym punktem wyjscia do stworzenia własnego krążka.
                        </div>
                        <div className="col-md-4">
                            <hr />
                            <h3>RockersPro Sp. j.</h3>

                            <p>
                                ul. Wilcza 27<br />
                                50-429 Wrocław<br />
                                NIP: 899-27-65-387
                            </p>

                            <p>
                                <strong>PEKAO S.A.</strong>
                                <br />
                                41 1240 4025 1111 0010 6873 6162
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
