import React, { Component } from 'react';
import Logo from '../../images/rockerspro.svg';

export default class RockersPro extends Component {
    static displayName = RockersPro.name;

    render() {
        return (
            <img src={Logo} className="rockerspro" alt="RockersPro" />
        );
    }
}