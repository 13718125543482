import React, { Component } from 'react';

export default class About extends Component {
    render() {
        return (
            <div id="about">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae congue lorem, vitae tempor nisi. Suspendisse sed venenatis nulla. Etiam in molestie purus, sit amet tincidunt tortor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut sit amet vestibulum est. Suspendisse pulvinar tortor vitae mi sagittis, quis ultricies neque semper. Etiam varius at enim ac pulvinar. Curabitur tellus dolor, pellentesque eu mauris a, semper vehicula nulla. Nunc a nunc est. Etiam ullamcorper lectus eget placerat posuere. In hac habitasse platea dictumst.
            </div>
        );
    }
}
