import React, { Component } from 'react';
import RockersPro from '../common/RockersPro';

export default class Header extends Component {
    render() {
        return (
            <div id="header">
                <RockersPro />
            </div>
        );
    }
}
