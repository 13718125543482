import { extend } from 'jquery';
import React, { Component, Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'


export default class ContactSection extends Component {
    render() {
        let contact = [];

        if(this.props.name != undefined && this.props.name != ""){
            contact.push(<h2 key="0">{this.props.name}</h2>)
        }

        if (this.props.image != undefined && this.props.image != "") {
            contact.push(
                <Fragment key="1">
                    <ContactSectionImage image={this.props.image} />
                    <hr />
                </Fragment>
            )
        }

        if(this.props.phone != undefined && this.props.phone != ""){
            contact.push(<ContactSectionPhone key="2" phone={this.props.phone} />)
        }

        if(this.props.mail != undefined && this.props.mail != ""){
            contact.push(<ContactSectionMail key="3" mail={this.props.mail} />)
        }

        if(this.props.children != undefined && this.props.children != ""){
            contact.push(
                <Fragment key="4">
                    <hr />
                    <p>
                        {this.props.children}
                    </p>
                </Fragment>
            )
        }

        return (
            <div className="contact-section">
                {contact}
            </div>
        );
        
    }
}

export class ContactSectionPhone extends Component {
    renderPhone(phone, key = 0) {
        return (
            <Fragment key={key}><FontAwesomeIcon icon={faPhone} /> <a href={"tel:" + phone}>{phone}</a><br /></Fragment>
            )
    }

    getPhoneAsArray() {
        let section = [];
        let i = 0;
        for (let phone of this.props.phone) {
            section.push(this.renderPhone(phone, i++));
        }

        return (
            <>
                {section}
            </>
        );
    }

    render() {
        if (Array.isArray(this.props.phone)) {
            return this.getPhoneAsArray();
        } else {
            return (
                this.renderPhone(this.props.phone)
            );
        }
    }
}

export class ContactSectionMail extends Component {
    renderMail(mail, key = 0) {
        return (
            <Fragment key={key}><FontAwesomeIcon icon={faEnvelope} /> <a href={"mailto:" + mail}>{mail}</a><br /></Fragment>
        )
    }

    getMailAsArray() {
        let section = [];
        let i = 0;
        for (let mail of this.props.mail) {
            section.push(this.renderMail(mail, i++));
        }

        return (
            <>
                {section}
            </>
        );
    }

    render() {
        if (Array.isArray(this.props.mail)) {
            return this.getMailAsArray();
        } else {
            return (
                this.renderMail(this.props.mail)
            );
        }
    }
}

export class ContactSectionImage extends Component {
    render() {
        return <img src={this.props.image} className="rounded" alt="Contact image" />;
    }
}

