import React, { Component } from 'react';

export default class Contact extends Component {
    render() {
        return (
            <div id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h2><u>Napisz do nas</u></h2>
                            <form>
                                <div class="form-group">
                                    <label for="contact-to">Adresat</label>
                                    <select class="form-control" id="contact-to">
                                        <option>Produkcja {'<'}produkcja@rockers.pl{'>'}</option>
                                        <option>Dystrybucja handel@rockers.pl</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="contact-from">Nadawca (e-mail)</label>
                                    <input class="form-control" id="contact-from" type="text"></input>
                                </div>
                                <div class="form-group">
                                    <label for="contact-topic">Temat</label>
                                    <input class="form-control" id="contact-topic" type="text"></input>
                                </div>
                                <div class="form-group">
                                    <label for="contact-content">Treść</label>
                                    <textarea class="form-control" id="contact-content" />
                                </div>
                                <button class="btn btn-primary" type="submit">Wyślij</button>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <h2><u>Dane kontaktowe</u></h2>
                            <b>RockersPro Sp. j.</b><br />
                            ul. Wilcza 27<br />
                            50-429 Wrocław<br />
                            NIP: 899-27-65-387<br />
                            <br />
                            Produkcja<br />
                            +4866<br />
                            <br />
                            Dystrybucja<br />
                            +48xxx<br />
                            <br />
                            Sklep<br />
                            +48cc


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
