import React, { Component } from 'react';
import Header from './components/index/Header';
import Services from './components/index/Services';
import About from './components/index/About';
import Contact from './components/index/Contact';
import Map from './components/index/Map';
import Footer from './components/index/Footer';
import SimpleContact from './components/index/SimpleContact';

import 'bootswatch/dist/lux/bootstrap.min.css'
import './rockers.css'

export default class RockersApp extends Component {
    static displayName = RockersApp.name;

    render() {
        return (
            <>
                <Header />
                <SimpleContact />
                <Footer />
             </>
        );
    }
}

/*
                <Services />
                <About />
                <Contact />
                <Map />
                <Footer />
                */
